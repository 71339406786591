import Header from './header';
import { index3Slider } from './sliders/index-3-slider';
import FormTextareaResize from './form-textarea-resize';
import { FileDragDrop } from './FileDragDrop';
import { productGallerySliderInit } from './sliders/product-gallery-slider';
import { productTabsInit } from './product-tabs';
import { lightboxInit } from './lightbox';
import Map from './Map';
import { handleFooterMenu } from './handle-footer-menu';
import { SidePanel } from './SidePanel';
import { choicesFormInit } from './choices-form-init';
import { setSectionsHeight } from './index-catalog-sections';
import Aos from 'aos';
import { ThingsSlider } from './sliders/ThingsSlider';
import { catalogFilter } from './catalog-filter';
import { formPhoneMask, formProductTitle } from './forms';
import { catalogDropdown } from './catalog-dropdown';
import { SearchBar } from './search';

export default class App {
  constructor() {
    this.init();
  }

  init() {
    window.addEventListener('DOMContentLoaded', (event) => {
      const header = new Header();
      // Main screen slider

      const indexSlider = new ThingsSlider({
        selector: '.index-slider',
        thingsSelector: '.thing',
        elSuffix: '',
      });

      const index5Slider = new ThingsSlider({
        selector: '.index-5-slider',
        thingsSelector: '.thing-5',
        elSuffix: '-5',
      });

      index3Slider();

      formPhoneMask();
      formProductTitle();
      const formTextareaResize = new FormTextareaResize('.form__textarea');

      const searchBar = new SearchBar();
      handleFooterMenu();

      const fileDragDrop = new FileDragDrop('#drop-area');

      // choicesInit();
      catalogFilter();
      catalogDropdown();
      choicesFormInit();

      productGallerySliderInit();

      productTabsInit();

      lightboxInit();

      const sidePanel = new SidePanel({ openSelector: '.side-open' });

      const contactsMap = new Map({
        id: '.contacts__item-map',
        placemarks: '.contacts__contact',
      });

      Aos.init({
        offset: 32,
        duration: 300,
        easing: 'custom',
        delay: 50,
        once: true,
      });

      // Wait for everything to load
      window.addEventListener('load', (event) => {
        setSectionsHeight();
        // init animations
      });

      if (typeof BX !== 'undefined') {
        BX.addCustomEvent('onAjaxSuccess', function () {
          catalogDropdown();
        });
      }
    }); //end Dom loaded
  }
}
