import Choices from 'choices.js';

export const catalogDropdown = () => {
  const selects = document.querySelector('.catalog__category-select');

  if (selects) {
    const choices = new Choices(selects, {
      searchEnabled: false,
      itemSelectText: '',
      shouldSort: false,
      classNames: {
        containerInner: ['choices__inner catalog__category-dropdown-choices'],
      },
    });

    selects?.addEventListener('change', (event) => {
      if (event.target?.value) {
        document.location.href = event.target.value;
      }
    });
  }
};
