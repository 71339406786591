import GLightbox from 'glightbox';
import Swiper from 'swiper';
import { Navigation, Thumbs } from 'swiper/modules';

export const productGallerySliderInit = () => {
  const productGallery = document.querySelector('.product__gallery');
  const productGalleryImagesWrapper = productGallery?.querySelector('.product__gallery-images');

  const productGalleryThumbsWrapper = productGallery?.querySelector('.product__gallery-thumbs');

  if (productGalleryImagesWrapper && productGalleryThumbsWrapper) {
    const productGalleryThumbs = new Swiper(productGalleryThumbsWrapper, {
      slidesPerView: 'auto',
      loop: true,
      freeMode: true,
      // watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });
    const productGalleryImages = new Swiper(productGalleryImagesWrapper, {
      slidesPerView: 1,
      spaceBetween: 1,
      loop: true,
      thumbs: { swiper: productGalleryThumbs },
      modules: [Navigation, Thumbs],
    });
  }
};
